.image-upload-wrapper .buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image-upload-wrapper .img {
  width: 200px;
}
.image-upload-wrapper .images {
  display: flex;
  justify-content: center;
}

.image-upload-wrapper input[type="file"] {
  visibility: hidden;
  position: absolute;
}

/* .image-upload-wrapper img {
  height: 160px;
  width: 200px;
  padding: 10px;
  border: 1px dashed #999;
  
} */
.image-upload-wrapper img {
  width: 100%;
  object-fit: contain;
  border: none;
  height: unset;
}
